import {
    FormControl,
    FormLabel,
    Icon,
    Input,
    SimpleGrid,
    Textarea
} from '@chakra-ui/react'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import Client from '../../models/client'
import ErrorHelper from '../ErrorHelper'
import Search from '../Icon/Search'
import TypeDocument from './TypeDocument'
import { FormBody } from './types'

export default function BodyContent({
    errorHelper,
    onChanges,
    edition,
    form,
    handleChangeForm,
    handleSearchSunat
}: {
    errorHelper: Partial<Record<keyof FormBody, string>>
    onChanges: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    edition?: Client
    handleChangeForm: (
        ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
    form: {
        number: string
        firstname: string
        lastname: string
        business_name: string
        address: string
    }
    handleSearchSunat: (type_document: string, number: string) => void
}) {
    const [typeDocument, setTypeDocument] = useState(() =>
        edition ? edition.type_identification_document_id.toString() : '1'
    )

    const [loadingSunat, setLoadingSunat] = useState(false)

    const _handleSearchSunat = async () => {
        setLoadingSunat(true)
        await handleSearchSunat(typeDocument, form.number)
        setLoadingSunat(false)
    }

    const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            _handleSearchSunat()
        }
    }

    const isDniOrRuc = typeDocument === '1' || typeDocument === '2'

    return (
        <>
            <TypeDocument value={typeDocument} onChange={setTypeDocument} />
            <FormControl mb={4} isRequired position="relative">
                <FormLabel>N° de documento</FormLabel>
                <Input
                    placeholder="Ingresar número de documento"
                    name="number"
                    onChange={handleChangeForm}
                    maxLength={180}
                    value={form.number}
                    {...(isDniOrRuc && {
                        onKeyDown: handleKeyDown
                    })}
                />

                {isDniOrRuc && (
                    <Icon
                        as={Search}
                        fill="gray-3"
                        cursor="pointer"
                        fontSize="2xl"
                        position="absolute"
                        right={3}
                        top={10}
                        zIndex={1}
                        opacity={!loadingSunat && form.number ? '1' : '.5'}
                        pointerEvents={
                            !loadingSunat && form.number ? 'auto' : 'none'
                        }
                        onClick={_handleSearchSunat}
                    />
                )}
                <ErrorHelper helper={errorHelper.number} />
            </FormControl>
            {typeDocument !== '2' && (
                <SimpleGrid columns={2} columnGap={4} mb={4}>
                    <FormControl isRequired>
                        <FormLabel>Nombres</FormLabel>
                        <Input
                            placeholder="Ingresar nombres"
                            name="firstname"
                            onChange={handleChangeForm}
                            value={form.firstname}
                            maxLength={180}
                        />
                        <ErrorHelper helper={errorHelper.firstname} />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Apellidos</FormLabel>
                        <Input
                            placeholder="Ingresar apellidos"
                            name="lastname"
                            onChange={handleChangeForm}
                            maxLength={180}
                            value={form.lastname}
                        />
                        <ErrorHelper helper={errorHelper.lastname} />
                    </FormControl>
                </SimpleGrid>
            )}
            {typeDocument === '2' && (
                <FormControl mb={4} isRequired>
                    <FormLabel>Razón social</FormLabel>
                    <Input
                        placeholder="Ingresar nombre del negocio"
                        name="business_name"
                        onChange={handleChangeForm}
                        maxLength={180}
                        value={form.business_name}
                    />
                    <ErrorHelper helper={errorHelper.business_name} />
                </FormControl>
            )}
            <FormControl mb={4} isRequired>
                <FormLabel>Dirección</FormLabel>
                <Textarea
                    placeholder="Dirección"
                    name="address"
                    onChange={handleChangeForm}
                    maxLength={150}
                    value={form.address}
                />
                <ErrorHelper helper={errorHelper.address} />
            </FormControl>
            <SimpleGrid columns={2} columnGap={4}>
                <FormControl>
                    <FormLabel>Teléfono</FormLabel>
                    <Input
                        placeholder="Ingresar teléfono"
                        name="phone"
                        type="number"
                        onChange={onChanges}
                        max={15}
                        defaultValue={edition?.phone || ''}
                    />
                    <ErrorHelper helper={errorHelper.phone} />
                </FormControl>
                <FormControl>
                    <FormLabel>Correo</FormLabel>
                    <Input
                        placeholder="Ingresar correo"
                        name="email"
                        onChange={onChanges}
                        maxLength={180}
                        defaultValue={edition?.email || ''}
                    />
                    <ErrorHelper helper={errorHelper.email} />
                </FormControl>
            </SimpleGrid>
        </>
    )
}
