import { useEffect, useState } from 'react'
import useRunway from '../../hooks/useRunway'
import { get } from '../../lib/http'
import { Result } from '../../lib/http/type'
import Client from '../../models/client'
import PageState from '../../types/pageState'
import { storagePage } from './storage'
import { PaymentTypeResponse } from './types'
import dayjs from 'dayjs'
import clearStates from './utilities/clearStates'
import useStorage from '../../hooks/useStorage'

export default function useInitial() {
    const [pageState, setPageState] = useState(PageState.LOADING)
    const { itemsForBilling, saveItemsForBilling } = useRunway()
    const { business_variables } = useStorage()

    async function load() {
        const request: Array<Promise<any>> = [
            get<Client>('/api/clients/1'),
            get<PaymentTypeResponse>('/api/type_payments/all')
        ]

        const [result1, result2] = (await Promise.all(request)) as [
            Result<Client>,
            Result<PaymentTypeResponse>
        ]

        if (result1.error) {
            setPageState(PageState.ERROR)
            return
        }

        storagePage.clientSelected = result1.data.result
        storagePage.defaultClient = result1.data.result
        storagePage.paymentTypes = result2.data.result.result
        storagePage.issueDate = dayjs().format('YYYY-MM-DD')
        storagePage.type = 'ticket'
        storagePage.daysIssue = parseInt(
            business_variables.find(v => v.code === '020')?.value ?? '0'
        )
        //clearStates()

        setPageState(PageState.SUCCESS)
    }

    useEffect(() => {
        storagePage.selectedItems = [...itemsForBilling]
        load()
        return () => {
            storagePage.selectedItems = []
            saveItemsForBilling([])
            clearStates()
        }
    }, [])

    return {
        pageState
    }
}
