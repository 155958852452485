import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    VStack,
    Textarea
} from '@chakra-ui/react'
import { BodyContentProps } from './types'

export default function BodyContent({ onChange, helpers }: BodyContentProps) {
    return (
        <VStack gap={2}>
            <HStack gap={4} alignItems="flex-start">
                <FormControl isRequired isInvalid={!!helpers.firstname}>
                    <FormLabel>Nombres</FormLabel>
                    <Input
                        placeholder="Ingresar nombres"
                        name="firstname"
                        maxLength={180}
                        autoFocus
                        onChange={onChange}
                    />
                    <FormErrorMessage>{helpers.firstname}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!helpers.lastname}>
                    <FormLabel>Apellidos</FormLabel>
                    <Input
                        placeholder="Ingresar apellidos"
                        name="lastname"
                        maxLength={180}
                        onChange={onChange}
                    />
                    <FormErrorMessage>{helpers.lastname}</FormErrorMessage>
                </FormControl>
            </HStack>
            <FormControl isRequired isInvalid={!!helpers.address}>
                <FormLabel>Dirección</FormLabel>
                <Input
                    placeholder="Ingresar dirección"
                    name="address"
                    maxLength={150}
                    onChange={onChange}
                />
                <FormErrorMessage>{helpers.address}</FormErrorMessage>
            </FormControl>
            <FormControl>
                <FormLabel>Referencia</FormLabel>
                <Textarea
                    name="reference"
                    placeholder="Ingresar referencia. Ejemplo: a dos cuadras del hospital"
                    maxLength={150}
                ></Textarea>
            </FormControl>
            <FormControl isRequired isInvalid={!!helpers.phone}>
                <FormLabel>Teléfono</FormLabel>
                <Input
                    placeholder="Ingresar teléfono"
                    name="phone"
                    type="number"
                    max={15}
                    onChange={onChange}
                />
                <FormErrorMessage>{helpers.phone}</FormErrorMessage>
            </FormControl>
        </VStack>
    )
}
