import { useToast } from '@chakra-ui/react'
import { FormEvent } from 'react'
import { render } from 'redity'
import { post, put } from '../../../lib/http'
import modal, { close, loading } from '../../../lib/modal'
import val from '../../../lib/val'
import { Keys } from '../constant'
import ModalMovement from '../ContentModals/ModalMovement'
import { formMovement, storagePage } from '../storage'
import { CashboxUserData, MovimentCustom } from '../types'
import loadMain from '../utilities/loadMain'

export default function useMovements(movement?: MovimentCustom) {
    const toast = useToast()
    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = formMovement.store()

        const cashboxUser = storagePage.cashboxUser as CashboxUserData

        const v = val(form)
        v.type.isEmpty('Es requerido el tipo de movimiento')
        v.concept.isEmpty('Es requerido el concepto')
        if (Number(form.type) === 2) {
            v.amount
                .isEmpty('Es requerido el monto')
                .isEqual(0, 'El monto debe ser mayor a 0')
                .isGreater(
                    cashboxUser.cash +
                        cashboxUser.amount_collection.cash +
                        cashboxUser.income +
                        cashboxUser.started_amount -
                        cashboxUser.expenses,
                    'El monto de salida/egreso no puede ser mayor al total de efectivo actual'
                )
        } else {
            v.amount
                .isEmpty('Es requerido el monto')
                .isEqual(0, 'El monto debe ser mayor a 0')
        }

        const result = v.runtest()

        if (result) {
            formMovement.setHelpers(result)
            return
        }

        loading()
        const dataMovement = {
            amount: Number(form.amount),
            cashbox_user_id: cashboxUser.id,
            comment: form.comment,
            concept: parseInt(form.concept),
            type: parseInt(form.type)
        }
        let res = null
        if (movement) {
            res = await put(`/api/movements/${movement.id}`, dataMovement)
        } else {
            res = await post('/api/movements', dataMovement)
        }

        if (res.error) {
            toast({
                title: `Lo sentimos, hubo problemas para ${
                    movement
                        ? 'editar el movimiento'
                        : 'añadir un nuevo movimiento'
                }. Vuelva intentar`,
                status: 'error',
                position: 'top'
            })
        } else {
            await loadMain(storagePage.currentCashbox?.id || 1)
            toast({
                title: `Se ha ${
                    movement
                        ? 'editado el movimiento'
                        : 'añadido un nuevo movimiento'
                } `,
                status: 'success',
                position: 'top'
            })
            render(Keys.MAIN)
        }
        close()
    }

    function clickAddHandler() {
        formMovement.init()
        if (movement) {
            formMovement.store.set('amount', movement.amount.toString())
            formMovement.store.set('comment', movement.comment)
            formMovement.store.set(
                'concept',
                movement.concept_id?.toString() || ''
            )
            formMovement.store.set('type', movement.type_id?.toString() || '')
        }

        formMovement.init(formMovement.store)
        modal(ModalMovement, {
            size: 'lg',
            title: movement ? 'Editar Movimiento' : 'Nuevo Movimiento',
            isCentered: false,
            onSubmit: submitHandler,
            centerTitle: true
        })
    }

    return {
        clickAddHandler
    }
}
