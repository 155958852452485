import { StoragePage } from './types'

export const storagePage: StoragePage = {
    clientSelected: null,
    defaultClient: null,
    issueDate: '',
    dueDate: '',
    paymentTypes: [],
    condition: 'cash',
    cash: [],
    card: [],
    transfer: [],
    typeTax: 'levied',
    creditFees: [],
    typeDiscount: 'amount',
    discount: 0,
    type: 'ticket',
    selectedItems: [],
    serie: 0,
    series: [],
    daysIssue: 0
}
